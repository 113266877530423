import { makeStyles } from '@material-ui/styles';
import AppBar from '@root/components/layout/AppBar';
import AppRoutes from '@root/routes/AppRoutes';
import { getWindowSize } from '@root/utils/helpers';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import DXLoadingScreen from '@root/components/common/loadingIndicator/DXLoadingScreen';
import { AuthContext } from '../components/AuthPages/AuthProvider';
import appActions from '../redux/app/actions';
import siteActions from '../redux/site/actions';
import {
  ACCESS_TOKEN_KEY,
  LogginCheckStatus,
  SignupCheckStatus,
  REFRESH_TOKEN_KEY,
} from '../utils';
import ModalInfo from './ModalInfo';

const useStyles = makeStyles(() => ({
  root: { flexGrow: 1 },
  connectCognito: {
    display: 'flex',
    width: '100%',
    height: '70vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
}));

const AppWithRouterAccess = () => {
  const dispatch = useDispatch();

  const classes = useStyles();
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const currentAccessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const [isAccess, setCheckAccess] = useState(false);
  const [isOpen, setIsOpen] = useState('');
  const [isRefresh, setIsRefresh] = React.useState(false);
  const [isOpenWarmingPopUp, setIsOpenWarmingPopUp] = useState(false);
  // GET_CURRENT_USER fetch state
  const loadingUser = useSelector((state) => state.app.loadingUser);
  const currentUser = useSelector((state) => state.app.currentUser);
  const siteAdmin = useSelector((state) =>
    state.site.siteList.filter(
      (s) => s.isAdmin === true || s.isAdmin === 'true',
    ),
  );

  const [windowSize, setWindowSize] = React.useState(getWindowSize());

  const [loggedIn, setLoggedIn] = useState(
    !currentAccessToken
      ? LogginCheckStatus.FAIL
      : LogginCheckStatus.NOT_CHECK_YET,
  );

  const [signup, setSignup] = useState(SignupCheckStatus.FAIL);
  const { logout, getSession } = useContext(AuthContext);

  const handleLogout = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    logout();
    navigate('/login', { replace: true });
  };

  useEffect(() => {
    getSession()
      .then(() => {
        setLoggedIn(LogginCheckStatus.SUCCESS);
        dispatch(appActions.getCurrentUser());
        // In below pages, it will need to query product plans too.
        let expand = '';
        if (
          [
            '/admin-center/activity/user-activity',
            '/admin-center/activity/product-data-statistic',
          ].includes(window.location.pathname)
        )
          expand = 'plan';

        dispatch(siteActions.getSiteList({ expand }));
      })
      .catch(() => {
        setLoggedIn(LogginCheckStatus.FAIL);
      });
  }, [currentLocation?.pathname]);

  useEffect(() => {
    if (
      window.location.pathname.includes('/login') ||
      window.location.pathname.includes('/forgotPassword')
    ) {
      if (loggedIn === LogginCheckStatus.SUCCESS) {
        navigate('/', { replace: true });
        setCheckAccess(true);
      }
    } else if (!loadingUser) {
      if (!currentUser && loggedIn === LogginCheckStatus.SUCCESS) {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
        localStorage.removeItem(REFRESH_TOKEN_KEY);
        navigate('/login', { replace: true });
      }
      setCheckAccess(true);
    }
  }, [loadingUser, currentUser, loggedIn, navigate]);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (
      window.location.pathname === '/login' ||
      window.location.pathname === '/forgotPassword'
    ) {
      // fix bug scroll in mac
      document.querySelector('body').classList.add('auth-page');
    } else {
      document.querySelector('body').classList.remove('auth-page');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  useEffect(() => {
    if (currentUser !== null) {
      if (currentUser?.atlassianAccountId === undefined) {
        setIsOpenWarmingPopUp(true);
      } else {
        setIsOpenWarmingPopUp(false);
      }
    }
  }, [currentUser]);

  // #endregion useEffect
  return (
    <>
      {loggedIn === LogginCheckStatus.SUCCESS && (
        <AppBar
          windowSize={windowSize}
          handleLogout={handleLogout}
          isAccess={isAccess}
          setIsRefresh={setIsRefresh}
          siteAdmin={siteAdmin}
        />
      )}
      {!isAccess && loggedIn === LogginCheckStatus.SUCCESS ? (
        <DXLoadingScreen />
      ) : (
        <div className={isOpenWarmingPopUp ? classes.noneDisplay : ''}>
          <AppRoutes
            signup={signup}
            setSignup={setSignup}
            loggedIn={loggedIn}
            isRefresh={isRefresh}
            setIsRefresh={setIsRefresh}
            siteAdmin={siteAdmin}
          />
        </div>
      )}
      {isOpen !== '' && (
        <ModalInfo
          isOpen={isOpen}
          changedOrg={() => setIsRefresh(true)}
          setIsOpen={setIsOpen}
          siteAdmin={siteAdmin}
        />
      )}
    </>
  );
};
export default AppWithRouterAccess;
