import React, { useEffect, useState } from 'react';
import Select, { components } from '@atlaskit/select';
import WarningIcon from '@atlaskit/icon/glyph/warning';
import { Field } from '@atlaskit/form';
import { makeStyles, styled } from '@material-ui/styles';
import { Checkbox } from '@atlaskit/checkbox';
import Badge from '@atlaskit/badge';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const useStyles = makeStyles(() => ({
  select: {
    width: '100%',
    minWidth: 220,
  },
  fieldBox: {
    display: 'flex',
    alignItems: 'center',
  },
  selectMultipleOrg: {
    display: 'flex',
    alignItems: 'center',
    gap: 16,
    width: '100%',
    minWidth: 230,
    fontSize: '14px',
    '& > div': {
      minWidth: '200px',
    },
    '@media screen and (max-width: 1024px)': {
      flex: 1,
    },
  },
}));

const LabelCheckBox = styled('label')({
  '& > label': {
    textWrap: 'nowrap',
  },
});

const NoOptionMessageCustom = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.NoOptionsMessage {...props}>
    <span>{i18next.t('common.noDataAvailable')}</span>
  </components.NoOptionsMessage>
);
const OptionCustom = ({ data, isSelected, ...props }) => {
  const { label, isDisabled = false } = data;
  const { t } = useTranslation();
  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 20,
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          fontSize: 14,
        }}
      >
        <Checkbox isChecked={isSelected} isDisabled={isDisabled} />
        {isDisabled ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <WarningIcon primaryColor='#E5686F' />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginLeft: 4,
              }}
            >
              <div>{label}</div>
              <div style={{ color: '#E5686F', fontSize: 10 }}>
                {t('common.licenseOutdate')}
              </div>
            </div>
          </div>
        ) : (
          <span>{label}</span>
        )}
      </div>
    </components.Option>
  );
};

const SelectMultiple = ({
  options,
  values,
  name = 'select-name',
  showMaxValue = 1,
  onMenuClose = () => {},
  onChange = () => {},
  ...props
}) => {
  const classes = useStyles();

  const MultiValueCustom = ({ data, ...props }) => {
    const NUMBER_SHOW = showMaxValue;
    const index = values?.findIndex((item) => item.value === data.value);
    if (index > NUMBER_SHOW) return <></>;
    if (index === NUMBER_SHOW) {
      return (
        <Badge>
          <div style={{ fontWeight: 600 }}>+{values.length - NUMBER_SHOW}</div>
        </Badge>
      );
    }
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <components.MultiValue {...props}>
        <span>{data?.label}</span>
      </components.MultiValue>
    );
  };

  return (
    <Select
      className={classes.select}
      options={options}
      value={values}
      onChange={onChange}
      onMenuClose={onMenuClose}
      components={{
        Option: OptionCustom,
        MultiValue: MultiValueCustom,
        NoOptionsMessage: NoOptionMessageCustom,
      }}
      menuPosition='fixed'
      name={name}
      isMulti
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      filterOption={(option, inputValue) => option.label.includes(inputValue)}
      {...props}
    />
  );
};

const SelectMultipleOrganizations = ({
  options,
  values,
  name = 'organizations',
  setValues = () => {},
  onMenuClose = () => {},
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const onChange = (data) => {
    if (data.length === options.length) {
      setIsAllSelected(true);
      setValues(data);
    } else {
      setIsAllSelected(false);
      setValues(data.length === 0 ? [options[0]] : data);
    }
  };

  const handleCheckAll = () => {
    setIsAllSelected(!isAllSelected);
    const orgs = isAllSelected ? [options[0]] : options;
    setValues(orgs);
  };

  useEffect(() => {
    setValues([options[0]]);
  }, [JSON.stringify(options)]);

  return (
    <div className={classes.selectMultipleOrg}>
      <span>{t('common.atlas.organization')}:</span>
      <SelectMultiple
        options={options}
        values={values}
        name={name}
        onChange={onChange}
        onMenuClose={onMenuClose}
      />
      <LabelCheckBox>
        <Checkbox
          label={t('common.all')}
          isChecked={isAllSelected}
          onClick={handleCheckAll}
        />
      </LabelCheckBox>
    </div>
  );
};

const SelectMultipleField = ({
  name,
  label,
  options,
  values,
  showMaxValue = 1,
  setValues = () => {},
  ...props
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleOnchange = (data) => {
    const validSite = options.filter((option) => !option.isDisabled);
    if (data.length === 0) {
      const selectedSite = (validSite && [validSite[0]]) || [];
      setValues(selectedSite);
    } else {
      setValues(data);
    }
    if (data.length === validSite.length) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  };

  const handleCheckAll = () => {
    const validSite = options.filter((option) => !option.isDisabled);
    const selectedSite = (validSite && [validSite[0]]) || [];
    const orgs = isAllSelected ? selectedSite : validSite;
    setValues(orgs);
    if (validSite.length === 1) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(!isAllSelected);
    }
  };

  useEffect(() => {
    const validSite = options.filter((option) => !option.isDisabled);
    if (values.length === 0) {
      const selectedSite = (validSite && [validSite[0]]) || [];
      setValues(selectedSite);
    }
    if (validSite.length === 1) {
      setIsAllSelected(true);
    }
  }, []);

  return (
    <Field isRequired name={name} label={label} {...props}>
      {({ fieldProps: { isDisabled } }) => (
        <div className={classes.fieldBox}>
          <SelectMultiple
            className={classes.select}
            options={options}
            values={values}
            name={name}
            isDisabled={isDisabled}
            showMaxValue={showMaxValue}
            onChange={handleOnchange}
          />
          <LabelCheckBox>
            <Checkbox
              label={t('common.all')}
              isChecked={isAllSelected}
              onClick={handleCheckAll}
            />
          </LabelCheckBox>
        </div>
      )}
    </Field>
  );
};

export { SelectMultiple, SelectMultipleOrganizations, SelectMultipleField };
