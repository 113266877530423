const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};

const getModalStatus = (modals, key) => {
  const modal = modals.find((m) => m.key === key);
  const isOpen = modal?.state === true;
  return {
    isOpen,
    data: modal?.data,
  };
};

export { getWindowSize, getModalStatus };
